import React from "react"
import "./index.sass"
import Head from "next/head"

const App = React.memo(({Component, pageProps}) => {
  return (
    <React.Fragment>
      <Head>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="author" content="Anmol Virk" />
          <title>Anmol Virk | Full Stack developer based in Brisbane Australia</title>
          <meta name="description" content="I am passionate about building SEO-driven, high-performance, mobile-friendly websites with fantastic UI and UX." />
          <link rel="canonical" href="https://anmolvirk.com" />

          <meta name="application-name" content="Anmol Virk - Full Stack developer based in Brisbane Australia" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="default" />
          <meta name="apple-mobile-web-app-title" content="Anmol Virk - Full Stack developer based in Brisbane Australia" />
          <meta name="description" content="I am passionate about building SEO-driven, high-performance, mobile-friendly websites with fantastic UI and UX." />
          <meta name="format-detection" content="telephone=no" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="msapplication-config" content="/icons/browserconfig.xml" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="msapplication-tap-highlight" content="no" />
          <meta name="theme-color" content="#000000" />

          <link rel="apple-touch-icon" href="/images/icons/icon.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/images/icons/icon.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/images/icons/icon.png" />
          <link rel="apple-touch-icon" sizes="167x167" href="/images/icons/icon.png" />

          <link rel="icon" type="image/ico" sizes="32x32" href="/images/icons/favicon.ico" />
          <link rel="icon" type="image/ico" sizes="16x16" href="/images/icons/favicon.ico" />
          <link rel="manifest" href="/manifest.json" />
          <link rel="mask-icon" href="/images/icons/icon.png" color="#000000" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content="https://anmolvirk.com" />
          <meta name="twitter:title" content="Anmol Virk - Full Stack developer based in Brisbane Australia" />
          <meta name="twitter:description" content="I am passionate about building SEO-driven, high-performance, mobile-friendly websites with fantastic UI and UX." />
          <meta name="twitter:image" content="https://anmolvirk.com/images/preview/portfolio.webp" />
          <meta name="twitter:creator" content="@AnmolVirk" />

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Anmol Virk - Full Stack developer based in Brisbane Australia" />
          <meta property="og:description" content="I am passionate about building SEO-driven, high-performance, mobile-friendly websites with fantastic UI and UX." />
          <meta property="og:site_name" content="Anmol Virk - Full Stack developer based in Brisbane Australia" />
          <meta property="og:url" content="https://anmolvirk.com" />
          <meta property="og:image" content="https://anmolvirk.com/images/preview/portfolio.webp" />
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  )
})

export default App
